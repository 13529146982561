<template>
  <div>
    <el-card shadow="always">
      <el-row :gutter="20">
        <el-form ref="elForm" :model="formData" :rules="rules" size="mini" label-width="99px">
          <el-col :span="16">
            <el-row>
              <el-col :span="24">
                <el-form-item label="商品标题" prop="product_title">
                  <el-input type="textarea" v-model="formData.product_title" placeholder="请输入商品标题" clearable :style="{ width: '100%' }"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="商品详情" prop="product_title">
                  <el-input type="textarea" v-model="formData.product_desc" placeholder="请输入商品详情" clearable :style="{ width: '100%' }"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="商品数量" prop="product_stock">
                  <el-input-number v-model="formData.product_stock" placeholder="商品数量" :step="1" :min="0" step-strictly> </el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="商品图片" prop="product_detail_img">
                  <el-upload action=""
                             :http-request="uploadImage"
                             list-type="picture-card"
                             :before-upload="beforeAvatarUpload"
                  >
                    <i slot="default" class="el-icon-plus"></i>
                    <div slot="file" slot-scope="{ file }">
                      <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                      <span class="el-upload-list__item-actions">
                        <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                          <i class="el-icon-zoom-in"></i>
                        </span>
                        <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                          <i class="el-icon-delete"></i>
                        </span>
                      </span>
                    </div>
                  </el-upload>
                  <span style="color: rebeccapurple">第一张图片为封面图片</span>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="商品单价" prop="product_price">
                  <el-input v-model="formData.product_price" placeholder="请输入商品单价" clearable :style="{ width: '100%' }">
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="商品状态" prop="product_status">
                  <el-select v-model="formData.product_status" placeholder="请选择商品状态" clearable :style="{ width: '100%' }">
                    <el-option
                      v-for="(item, index) in product_statusOptions"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                      :disabled="item.disabled"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="24">
            <el-form-item size="large">
              <el-button :loading="loading" type="primary" @click="submitForm">提交</el-button>
              <el-button @click="resetForm">重置</el-button>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      loading: false,
      detailImgList: [],
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      formData: {
        product_title: undefined,
        product_stock: 0,
        product_price: undefined,
        product_status: 1,
        product_desc: undefined,
        product_img: undefined
      },
      rules: {
        product_title: [
          {
            required: true,
            message: '请输入商品标题',
            trigger: 'blur'
          }
        ],
        product_stock: [
          {
            required: true,
            message: '商品数量',
            trigger: 'blur'
          }
        ],
        product_price: [
          {
            required: true,
            message: '请输入商品单价',
            trigger: 'blur'
          }
        ],
        product_status: [
          {
            required: true,
            message: '请选择商品状态',
            trigger: 'change'
          }
        ]
      },
      product_statusOptions: [
        {
          label: '上架',
          value: 1
        },
        {
          label: '下架',
          value: 0
        }
      ]
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 1

      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 1MB!')
      }
      return isJPG && isLt2M
    },
    uploadImage(data) {
      const formdata = new FormData()
      formdata.append('file', data.file)
      this.$api.product.uploadCoverImg(formdata).then(res => {
        if (res.data.code === 200) {
          this.detailImgList.push(res.data.data)
        } else {
          this.$message.error({
            message: res.data.msg,
            center: true,
            duration: 5000
          })
        }
        console.log(this.detailImgList)
      })
    },
    handleRemove(file) {
      console.log(file)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    submitForm() {
      this.$refs['elForm'].validate(valid => {
        if (valid) {
          console.log(this.formData)
          this.formData.product_img = this.detailImgList
          this.$api.product.publishProduct(this.formData).then(res => {
            if (res.data.code === 200) {
              this.$message.success({
                message: '新增商品成功',
                center: true,
                duration: 2000
              })
              this.loading = false
              this.$router.push('/product_list')
            } else {
              this.$message.error({
                message: res.data.msg,
                center: true,
                duration: 5000
              })
              this.loading = false
            }
          })
        }
      })
    },
    resetForm() {
      this.$refs['elForm'].resetFields()
    }
  }
}
</script>

<style scoped></style>
